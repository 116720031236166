import { SIGNUP_QUERY_PARAMS, WORKFLOW_TYPES } from "../types/signup";

const DEFAULT_WORKFLOW_TYPE = WORKFLOW_TYPES.SIGNUP;

export function isDefaultWorkflow(workflowType: WORKFLOW_TYPES) {
  return workflowType === DEFAULT_WORKFLOW_TYPE;
}

export function isValidationWorkflow(workflowType: WORKFLOW_TYPES) {
  return workflowType === WORKFLOW_TYPES.VALIDATION;
}

type QueryParamsOpt = {
  workflowType?: WORKFLOW_TYPES;
  guildId?: string;
};

type QueryParamsReqAll = {
  workflowType: WORKFLOW_TYPES;
  guildId: string;
};

/**
 * Extract parameters from a request url.
 *
 * Workflow Types:
 * @see {@link WORKFLOW_TYPES}
 * Note: If no workflow type is present, the default is returned.
 *
 * DiscordId:
 * Unique identifier to identify verification data that is returned to the bot server.
 *
 * @param request
 * @returns
 */
export function parseParams(request: Request) {
  const url = new URL(request.url);
  const params: QueryParamsOpt = {};

  return {
    getGuildId: function () {
      const guildId = url.searchParams.get(SIGNUP_QUERY_PARAMS.GUILD_ID);
      if (guildId) {
        params.guildId = guildId;
      }
      return this;
    },
    getWorkflowType: function () {
      const workflowType = (url.searchParams.get(SIGNUP_QUERY_PARAMS.WORKFLOW_TYPE) ||
        DEFAULT_WORKFLOW_TYPE) as WORKFLOW_TYPES;
      params.workflowType = workflowType;
      return this;
    },

    /**
     * End the chain.
     *
     * @returns an object that can be destructured like const { workflowType, discordId }
     */
    extract: function () {
      return { ...params } as QueryParamsReqAll;
    },
  };
}

/**
 * Append the workflow type to a path. Will not append if the workflow type is the default.
 *
 * NOTE: Compatible with paths that already have query parameters.
 *
 * @param path
 * @param workflowType
 * @returns
 */
export function decorateWorkflowType(path: string, workflowType: WORKFLOW_TYPES) {
  if (isDefaultWorkflow(workflowType)) {
    // Default is not appended.
    return path;
  }

  // Using some trickery here with a dummy base URL to get the search params to append correctly.
  const url = new URL(path, "http://dummy-base");
  url.searchParams.set(SIGNUP_QUERY_PARAMS.WORKFLOW_TYPE, workflowType);

  return `${url.pathname}${url.search}`;
}
